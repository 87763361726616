<template>
  <div class="mb-5">
    <div class="ps-page--simple">
      <div class="ps-breadcrumb">
        <div class="container">
          <ul class="breadcrumb">
            <li>
              <router-link :to="{ name: 'home' }">Home</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'shop_products' }">Shop</router-link>
            </li>
            <li>Shopping Cart</li>
          </ul>
        </div>
      </div>
      <div class="ps-shopping-cart" style="margin-top: 50px;">
        <div class="ps-container">
          <h1 style="margin-bottom: 50px;">Shopping Cart</h1>
          <div class="ps-section__content">
            <div class="row">
              <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <div class="table-responsive" style="border:1px solid #bfbfbf" v-if="cart.cartCount > 0">
                  <table class="table ps-table--shopping-cart ps-table--responsive">
                    <thead>
                      <tr>
                        <th>Product name</th>
                        <th class="text-right">PRICE</th>
                        <th>QUANTITY</th>
                        <th>FEES</th>
                        <th class="text-right">TOTAL</th>
                        <th class="text-right">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <CartProduct v-for="cartItem in cart.cartItems" :cartProduct="cartItem" :key="cartItem.id">
                      </CartProduct>
                    </tbody>
                  </table>
                </div>

                <div class="alert alert-primary" role="alert" v-else>
                  Cart Is Empty !
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12" v-if="cart.cartCount > 0">
                <div class="ps-block--shopping-total">
                  <div class="ps-block__header">
                    <p>
                      Subtotal <span>{{ cart.total | toCurrency }}</span>
                    </p>
                  </div>
                  <div class="ps-block__content">
                    <ul class="ps-block__product">
                      <li>
                        <span class="ps-block__shop">Transport Cost</span>
                        <span class="ps-block__shipping">Free Shipping less than 1km</span>
                      </li>
                    </ul>
                    <h3>
                      Total <span>{{ cart.total | toCurrency }}</span>
                    </h3>
                  </div>
                </div>
                <div v-if="auth.isAuthenticated && cart.cartCount > 0">
                  <router-link :to="{ name: 'checkout' }" class="btn btn-primary ls-button">Checkout</router-link>
                </div>
                <div v-else-if="cart.cartCount > 0">
                  <button @click="onCheckout()" class="btn btn-primary ls-button">
                    Checkout
                  </button>
                </div>
              </div>
              <div class="col-12">
                <a class="ps-btn ps-btn--fullwidth mt-5" href="/shop">
                  Continue Shopping
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">

              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import global from "@/mixins/global.js";
import CartProduct from "../../../components/CartProduct";
import { mapState } from "vuex";
export default {
  components: { CartProduct },
  mixins: [global],

  data() {
    return {
      cartTotal: 0,
      totalCartItems: 0,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
      cartTotalAmount: (state) => state["cart/total"],
    }),
  },
  methods: {
    onCheckout() {
      this.$store.commit("auth/next", "checkout");
      this.$router.push({ name: "login" });
    },
  },
  mounted() {
    console.log(this.cart);
  }
};
</script>

<style lang="css" scoped>
.button {
  /* padding: 10px; */
  font-size: 15px;
}
</style>
